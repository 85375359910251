@font-face {
  font-family: 'muli-light';
  src: url('./../font/Muli-Light.ttf'); /* IE9 Compat Modes */
  src: url('./../font/Muli-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'muli-regular';
  src: url('./../font/Muli-Regular.ttf'); /* IE9 Compat Modes */
  src: url('./../font/Muli-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'muli-medium';
  src: url('./../font/Muli-SemiBold.ttf'); /* IE9 Compat Modes */
  src: url('./../font/Muli-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'muli-semi-bold';
  src: url('./../font/Muli-SemiBold.ttf'); /* IE9 Compat Modes */
  src: url('./../font/Muli-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'muli-bold';
  src: url('./../font/Muli-Bold.ttf'); /* IE9 Compat Modes */
  src: url('./../font/Muli-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}

/* San Francisco font*/
@font-face {
  font-family: 'sf-light';
  src: url('./../font/SF/Light/SFUIDisplay-Light.ttf'); /* IE9 Compat Modes */
  src: url('./../font/SF/Light/SFUIDisplay-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'sf-regular';
  src: url('./../font/SF/Regular/SFUIDisplay-Regular.ttf'); /* IE9 Compat Modes */
  src: url('./../font/SF/Regular/SFUIDisplay-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'sf-medium';
  src: url('./../font/SF/Medium/SFUIDisplay-Medium.ttf'); /* IE9 Compat Modes */
  src: url('./../font/SF/Medium/SFUIDisplay-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'sf-semi-bold';
  src: url('./../font/SF/Semibold/SFUIDisplay-Semibold.ttf'); /* IE9 Compat Modes */
  src: url('./../font/SF/Semibold/SFUIDisplay-Semibold.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'sf-bold';
  src: url('./../font/SF/Bold/SFUIDisplay-Bold.ttf'); /* IE9 Compat Modes */
  src: url('./../font/SF/Bold/SFUIDisplay-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}

